
import moment from 'moment';
import PercentageChange from '../helpers/percentage-change';
import UserCoverageModal from '../users/user-coverage-modal';
import modals from '../../mixins/modals';
import SearchSelect from '../search-select.vue';

export default {
    name: 'PeersList',
    components: {
        SearchSelect,
        PercentageChange,
        UserCoverageModal
    },
    mixins: [modals],
    props: {
        company: {
            type: Object,
            required: true
        },
        peerCompanies: {
            type: Array,
            required: true
        },
        addresses: {
            type: Array,
            default: null
        },
        cashBurn: {
            type: Array,
            default: null
        },
        dataWeeksAgo: {
            type: Array,
            default: null
        },
        peerGroupData: {
            type: Array,
            required: true
        },
        loadingManualPeers: {
            type: Boolean,
            required: true
        },
        allowEdit: {
            type: Boolean,
            default: false
        },
        perspective: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            coverageUserId: null,
            loadingPeersSuggestion: false,
            collapseShow: false,
            collapseHidden: true,
            manualPeerGroupsConfig: {
                columns: [
                    {
                        headline: 'Symbol',
                        sort: true
                    },
                    {
                        headline: 'Name',
                        sort: true
                    },
                    {
                        headline: 'HQ',
                        breakpoint: 'xl'
                    },
                    {
                        headline: 'Price',
                        sort: true
                    },
                    {
                        headline: '% Chg',
                        sort: true,
                        breakpoint: 'xl'
                    },
                    {
                        headline: '52 Weeks % Chg',
                        breakpoint: 'xl'
                    },
                    {
                        headline: 'Mkt Cap',
                        sort: true,
                        breakpoint: 'xl'
                    },
                    {
                        headline: 'Cash',
                        title: 'Cash and short term investments',
                        sort: true,
                        breakpoint: 'xl'
                    },
                    {
                        headline: 'Cash Burn',
                        sort: true
                    },
                    {
                        headline: 'Runway',
                        breakpoint: 'xl'
                    },
                    {
                        headline: 'Debt',
                        title: 'Total Debt',
                        sort: true,
                        breakpoint: 'xl'
                    },

                    {
                        headline: 'HCW Analysts'
                    }
                ],
                pagination: 10
            },
            updated: moment().unix(),
            manualPeerGroupForSearch: [],
            suggestionsPeerGroupsConfig: {
                columns: [
                    {
                        headline: 'Symbol',
                        sort: true
                    },
                    {
                        headline: 'Name',
                        sort: true
                    },
                    {
                        headline: 'HQ'
                    },
                    {
                        headline: 'Price',
                        sort: true
                    },
                    {
                        headline: '% Chg',
                        sort: true
                    },
                    {
                        headline: '52 Weeks % Chg',
                        breakpoint: 'xl'
                    },
                    {
                        headline: 'Mkt Cap',
                        sort: true
                    },
                    {
                        headline: 'Cash',
                        title: 'Cash and short term investments',
                        sort: true
                    },
                    {
                        headline: 'Cash Burn',
                        sort: true
                    },
                    {
                        headline: 'Runway'
                    },
                    {
                        headline: 'Debt',
                        title: 'Total Debt',
                        sort: true
                    },
                    {
                        headline: 'HCW Analysts'
                    },
                    {
                        headline: 'Actions',
                        hideHeadlineBreakpoint: 'all',
                        align: 'end'
                    }
                ],
                pagination: 10
            },
            suggestionsPeerGroupCompanies: [],
            suggestionsPeerGroupData: []
        };
    },
    computed: {
        rows() {

            return this.peerCompanies.map((item, index) => {
                let address = '';
                if (this.addresses && this.addresses[index]) {
                    address = this.addresses[index].replace('\n', ', ');
                } else if (item.address) {
                    address = item.address.replace('\n', ', ');
                }
                let formattedChange = '';
                if (this.peerGroupData[index]) {
                    const currentPrice = this.peerGroupData[index].price;
                    const previousPrice = this.dataWeeksAgo[index];

                    const percentageChange = (currentPrice - previousPrice) / previousPrice * 100;
                    formattedChange = this.formatPercentage(percentageChange);
                }

                let cashBurn = '';
                let runway = '';
                if (this.cashBurn.length > 0 && this.cashBurn[index]) {
                    cashBurn = this.cashBurn ? this.cashBurn[index].value : '';
                    runway = cashBurn;
                    cashBurn = this.formatNumber(cashBurn);
                }

                let stockInfo = this.peerGroupData.find((obj) => {
                    return obj.symbol === item.ticker;
                });
                if (!stockInfo) {
                    stockInfo = null;
                }
                if (stockInfo && !address) {
                    address = [stockInfo.city, stockInfo.state, stockInfo.country]
                        .filter((el) => el && el.length > 0)
                        .join(', ');
                }
                let cash = null;
                let debt = null;
                let balanceSheetCurrency = '';
                let balanceSheetDate = '';
                if (stockInfo && stockInfo.mostRecentBalanceSheet) {
                    balanceSheetDate = this.formatDate(stockInfo.mostRecentBalanceSheet.date);
                    if (stockInfo.mostRecentBalanceSheet.reportedCurrency) {
                        balanceSheetCurrency = stockInfo.mostRecentBalanceSheet.reportedCurrency;
                    }
                    if (stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments) {
                        cash = stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments;
                    }
                    if (stockInfo.mostRecentBalanceSheet.totalDebt) {
                        debt = stockInfo.mostRecentBalanceSheet.totalDebt;
                    }
                }
                if (runway !== '' && cash !== null) {
                    runway = this.parseFormattedNumber(cash) / runway;
                } else {
                    runway = 'NA';
                }

                return {
                    classes: item.id === this.company.id ? 'selected-company' : '',
                    cells: [
                        {
                            text: item.ticker
                        },
                        {
                            type: 'name',
                            item,
                            sortValue: item.name
                        },
                        {
                            text: address
                        },
                        {
                            text: stockInfo ? this.formatCurrency(stockInfo.price, stockInfo.currency) : '',
                            sortValue: stockInfo ? stockInfo.price : 0
                        },
                        {
                            type: 'percentage-change',
                            change: stockInfo ? stockInfo.changesPercentage : '',
                            sortValue: stockInfo ? stockInfo.changesPercentage : 0
                        },
                        {
                            text: formattedChange
                        },
                        {
                            text: stockInfo ? this.nFormatCurrency(stockInfo.currency, stockInfo.marketCap) : '',
                            sortValue: stockInfo ? stockInfo.marketCap : 0
                        },
                        {
                            html: cash
                                ? this.nFormatCurrency(balanceSheetCurrency, cash) +
                                '<br/><span class=\'text-muted\'>(' +
                                balanceSheetDate +
                                ')</span>'
                                : '',
                            sortValue: cash
                        },
                        {
                            text: cashBurn,
                            sortValue: cashBurn
                        },
                        {
                            text: this.formatRunway(runway)
                        },
                        {
                            html: debt
                                ? this.nFormatCurrency(balanceSheetCurrency, debt) +
                                '<br/><span class=\'text-muted\'>(' +
                                balanceSheetDate +
                                ')</span>'
                                : '',
                            sortValue: debt
                        },

                        {
                            type: 'ratings',
                            ratings: stockInfo ? stockInfo.ratings : []
                        }
                    ]
                };
            });
        },
        suggestionsPeerRows() {
            return this.suggestionsPeerGroupCompanies.map((item, index) => {
                let address = '';
                if (this.addresses && this.addresses[index]) {
                    address = this.addresses[index].replace('\n', ', ');
                } else if (item.address) {
                    address = item.address.replace('\n', ', ');
                }
                let formattedChange = '';
                if (this.peerGroupData[index]) {
                    const currentPrice = this.peerGroupData[index].price;
                    const previousPrice = this.dataWeeksAgo[index];

                    const percentageChange = (currentPrice - previousPrice) / previousPrice * 100;
                    formattedChange = this.formatPercentage(percentageChange);
                }

                let cashBurn = '';
                let runway = '';
                if (this.cashBurn.length > 0 && this.cashBurn[index]) {
                    cashBurn = this.cashBurn ? this.cashBurn[index].value : '';
                    runway = cashBurn;
                    cashBurn = this.formatNumber(cashBurn);
                }

                let stockInfo = this.suggestionsPeerGroupData.find((obj) => {
                    return obj.symbol === item.ticker;
                });
                if (!stockInfo) {
                    stockInfo = null;
                }
                if (stockInfo && !address) {
                    address = [stockInfo.city, stockInfo.state, stockInfo.country]
                        .filter((el) => el && el.length > 0)
                        .join(', ');
                }
                let cash = null;
                let debt = null;
                let balanceSheetCurrency = '';
                let balanceSheetDate = '';
                if (stockInfo && stockInfo.mostRecentBalanceSheet) {
                    balanceSheetDate = this.formatDate(stockInfo.mostRecentBalanceSheet.date);
                    if (stockInfo.mostRecentBalanceSheet.reportedCurrency) {
                        balanceSheetCurrency = stockInfo.mostRecentBalanceSheet.reportedCurrency;
                    }
                    if (stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments) {
                        cash = stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments;
                    }
                    if (stockInfo.mostRecentBalanceSheet.totalDebt) {
                        debt = stockInfo.mostRecentBalanceSheet.totalDebt;
                    }
                }

                if (runway !== '' && cash !== null) {
                    runway = this.parseFormattedNumber(cash) / runway;
                } else {
                    runway = 'NA';
                }

                return {
                    classes: item.id === this.company.id ? 'selected-company' : '',
                    cells: [
                        {
                            text: item.ticker
                        },
                        {
                            type: 'name',
                            item,
                            sortValue: item.name
                        },
                        {
                            text: address
                        },
                        {
                            text: stockInfo ? this.formatCurrency(stockInfo.price, stockInfo.currency) : '',
                            sortValue: stockInfo ? stockInfo.price : 0
                        },
                        {
                            type: 'percentage-change',
                            change: stockInfo ? stockInfo.changesPercentage : '',
                            sortValue: stockInfo ? stockInfo.changesPercentage : 0
                        },
                        {
                            text: formattedChange
                        },
                        {
                            text: stockInfo ? this.nFormatCurrency(stockInfo.currency, stockInfo.marketCap) : '',
                            sortValue: stockInfo ? stockInfo.marketCap : 0
                        },
                        {
                            html: cash
                                ? this.nFormatCurrency(balanceSheetCurrency, cash) +
                                '<br/><span class=\'text-muted\'>(' +
                                balanceSheetDate +
                                ')</span>'
                                : '',
                            sortValue: cash
                        },
                        {
                            text: cashBurn,
                            sortValue: cashBurn
                        },
                        {
                            text: this.formatRunway(runway)
                        },
                        {
                            html: debt
                                ? this.nFormatCurrency(balanceSheetCurrency, debt) +
                                '<br/><span class=\'text-muted\'>(' +
                                balanceSheetDate +
                                ')</span>'
                                : '',
                            sortValue: debt
                        },

                        {
                            type: 'ratings',
                            ratings: stockInfo ? stockInfo.ratings : []
                        },
                        {
                            item,
                            type: 'add-to-manual',
                            hash: this.createSimpleHash(item.name)
                        }
                    ]
                };
            });
        }
    },
    watch: {
        peerCompanies: {
            handler() {
                this.manualPeerGroupForSearch = this.peerCompanies.filter((x) => x.id !== this.company.id);
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (this.allowEdit) {
            this.loadSuggestionsPeerGroups();
            this.$refs.suggestionCollapse.addEventListener('show.bs.collapse', () => {
                this.collapseShow = true;
                this.collapseHidden = false;
            });
            this.$refs.suggestionCollapse.addEventListener('hide.bs.collapse', () => {
                this.collapseShow = false;
            });
            this.$refs.suggestionCollapse.addEventListener('hidden.bs.collapse', () => {
                this.collapseHidden = true;
            });
        }
    },
    methods: {
        formatPercentage(number) {
            return number.toFixed(2) + '%';
        },
        formatRunway(runway) {

            if (runway === 'NA' || runway === Infinity || isNaN(runway)) {
                return 'NA';
            }
            const months = Math.floor(runway); // Whole months
            const days = Math.round((runway - months) * 30); // Approximate days (assuming 30 days per month)
            return `${months} month${months !== 1 ? 's' : ''} and ${days} day${days !== 1 ? 's' : ''}`;
        },
        parseFormattedNumber(formattedNumber) {
            formattedNumber = String(formattedNumber); // Ensure formattedNumber is a string
            const suffix = formattedNumber.slice(-1);
            const value = parseFloat(formattedNumber.slice(1, -1));
            switch (suffix) {
                case 'K':
                    return value * 1e3;
                case 'M':
                    return value * 1e6;
                case 'B':
                    return value * 1e9;
                default:
                    return parseFloat(formattedNumber);
            }
        },

        formatNumber(num) {
            if (num >= 1e9) {
                return (num / 1e9).toFixed(1) + 'B';
            } else if (num >= 1e6) {
                return (num / 1e6).toFixed(1) + 'M';
            } else if (num >= 1e3) {
                return (num / 1e3).toFixed(1) + 'K';
            } else {
                return num.toString();
            }
        },
        updatePeerGroup() {
            const data = {
                peerGroup: this.manualPeerGroupForSearch,
                perspective: this.perspective
            };
            this.$emit('update-peer-group', data);
        },
        addToManualPeerGroup(id) {
            this.manualPeerGroupForSearch.push({ id });
            const data = {
                peerGroup: this.manualPeerGroupForSearch,
                perspective: this.perspective
            };
            this.$emit('update-peer-group', data);
            setTimeout(() => {
                this.loadSuggestionsPeerGroups();
            }, 1000);
        },
        loadSuggestionsPeerGroups() {
            this.loadingPeersSuggestion = true;
            this.$axios
                .get('/api/companies/peer_group_suggestion/' + this.company.id)
                .then((response) => {
                    this.suggestionsPeerGroupCompanies = response.data.companies;
                    this.suggestionsPeerGroupData = response.data.stockData;
                    this.updated = moment().unix();
                })
                .finally(() => {
                    this.loadingPeersSuggestion = false;
                });
        },
        openCoverageModal(userId) {
            this.coverageUserId = userId;
            this.$nextTick(() => {
                this.coverageModal = this.openModal(this.$refs.userCoverageModal);
            });
        }
    }
};
