import { render, staticRenderFns } from "./upload-modal.vue?vue&type=template&id=7781779c&scoped=true"
import script from "./upload-modal.vue?vue&type=script&lang=js"
export * from "./upload-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7781779c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FileUploadInput: require('/codebuild/output/src2285020857/src/web/components/file-upload-input.vue').default,SaveButton: require('/codebuild/output/src2285020857/src/web/components/save-button.vue').default})
