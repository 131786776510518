import { render, staticRenderFns } from "./autosave-cell-date.vue?vue&type=template&id=1a5e7134&scoped=true"
import script from "./autosave-cell-date.vue?vue&type=script&lang=js"
export * from "./autosave-cell-date.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a5e7134",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2285020857/src/web/components/loading-spinner.vue').default})
