
import moment from 'moment';
import SaveButton from '../../save-button.vue';
import iriPreparation from '../../../mixins/iri-preparation';

export default {
    name: 'EditThirdPartyMeetingModal',
    components: { SaveButton },
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: 'editThirdPartyMeetingModal'
        },
        thirdPartyMeeting: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            thirdPartyMeetingWorkingCopy: {},
            loading: false,
            isNew: false
        };
    },
    computed: {
        // Validation for Participants and Participating Companies
        participantsAndCompaniesValidation() {
            const hasParticipants =
                this.thirdPartyMeetingWorkingCopy.participants?.length > 0;
            const hasCompanies =
                this.thirdPartyMeetingWorkingCopy.participatingCompanies?.length > 0;
            return hasParticipants || hasCompanies ? '' : 'required';
        }
    },
    watch: {
        thirdPartyMeeting: {
            handler(val) {
                this.thirdPartyMeetingWorkingCopy = structuredClone(val);
                this.thirdPartyMeetingWorkingCopy.date = this.thirdPartyMeetingWorkingCopy.date
                    ? moment(this.thirdPartyMeetingWorkingCopy.date).format('YYYY-MM-DD')
                    : null;
                this.isNew = !this.thirdPartyMeetingWorkingCopy.id;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        submitForm() {
            if (this.participantsAndCompaniesValidation) {
                this.$refs.interactionForm.showErrors();
                return;
            }

            this.loading = true;
            const thirdPartyMeetingIri = this.prepareIri(
                this.thirdPartyMeetingWorkingCopy
            );
            thirdPartyMeetingIri.participants = this.prepareIri(
                thirdPartyMeetingIri.participants
            );
            thirdPartyMeetingIri.participatingCompanies = this.prepareIri(
                thirdPartyMeetingIri.participatingCompanies
            );

            const request = this.isNew
                ? this.$axios.post(`/api/third_party_meetings`, thirdPartyMeetingIri)
                : this.$axios.patch(
                    `/api/third_party_meetings/${this.thirdPartyMeetingWorkingCopy.id}`,
                    thirdPartyMeetingIri,
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json'
                        }
                    }
                );

            request
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Third Party Meeting Saved',
                        message: 'The third party meeting has been saved successfully.',
                        id: toastId
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                        this.$emit('saved', response.data);
                    });
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading = false;
                });
        }
    }
};
